import React, { useEffect, useRef } from 'react';
import { navigate } from 'gatsby';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'dayjs/locale/nb';

import { useAuth } from 'context/AuthProvider';
import { useFiberOrderContext } from 'components/fiber-2.0/Provider';
import {
	Fieldset,
	ProductOptions,
	Req,
} from 'components/fiber-2.0/order/Styling';
import ContactInfo from 'components/fiber-2.0/order/fields/ContactInfo';
import ProductSelect from 'components/fiber-2.0/order/fields/ProductSelect';
import Button from 'components/forms/Button';
import Loading from 'components/Loading';
import { scrollToElement } from 'libs/content';
import TitleAndText from 'parts/title-and-text/TitleAndText';
import { trackBackEnd } from 'context/AnalyticsProvider';
import Error from 'components/error/Error';
import LeadForm from '../LeadForm';

dayjs.locale('nb');
dayjs.extend(customParseFormat);

/**
 * Represents a component for the first step in the fiber order form.
 * @param {Object} props - The properties for the Step1 component.
 * @param {boolean} [props.isBRL=false] - Indicates if the order form is for a BRL (Borettslag) product.
 * @returns {ReactNode} - A React element representing the Step1 component.
 **/
export default function Step1({ isBRL = false }) {
	const { method } = useAuth();
	const {
		mainPageSlug,
		address,
		setAddress,
		deliverResults,
		setDeliverResults,
		addOns,
		chosenAddOns,
		setChosenAddOns,
		removeFromChosenAddons,
		chosenProduct,
		setChosenProduct,
		order,
		setOrder,
		sortByPrice,
		loading,
		fetchTerms,
		errors,
		setErrors,
	} = useFiberOrderContext();
	const { loading: authLoading, isAuthenticated } = useAuth();
	const hasTracked = useRef(false);

	const formErrorsRef = useRef([]);

	// Function to fetch delivery terms
	const fetchDeliveryTerms = async () => {
		const deliveryTerms = await fetchTerms();

		if (
			typeof deliveryTerms === 'undefined' ||
			!deliveryTerms?.terms?.length > 0
		) {
			console.error('No delivery terms found');
			return;
		}
		// Set delivery terms in setDeliverResults
		setDeliverResults({
			...deliverResults,
			deliveryTerms: deliveryTerms?.terms,
			thankYouText: deliveryTerms?.thankYouText,
		});
	};

	useEffect(() => {
		if (!chosenProduct?.name) return;

		// If the user is authenticated, fetch delivery terms
		if (isAuthenticated) {
			fetchDeliveryTerms();
		}

		if (!hasTracked.current && isAuthenticated) {
			hasTracked.current = true;
			let option = 'Privat';
			if (deliverResults?.result === 'brl') option = 'Borettslag';
			if (deliverResults?.result === 'lead') option = 'Lead';
			if (deliverResults?.result === 'partner') option = 'Partner';

			trackBackEnd('Checkout Started', {
				option,
				landing_page: deliverResults?.deliverable?.landing_page,
				value: chosenProduct.priceMonth,
				currency: 'NOK',
				products: [
					{
						product_id: chosenProduct.api_id,
						name: chosenProduct.name,
						price: chosenProduct.priceMonth,
						quantity: 1,
						category: 'TV og Internett',
					},
				],
			});
		}

		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Fetch delivery terms when the component mounts
	// and when the user is authenticated
	useEffect(() => {
		if (!isAuthenticated || authLoading) return;

		fetchDeliveryTerms();
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading, isAuthenticated, authLoading]);

	/**
	 * Handles the click event for the proceed button
	 * Validates the form and proceeds to the next step
	 * @param {Object} e - The event object
	 * @returns {void}
	 **/
	function onProceedClick(e) {
		e.preventDefault();

		// Don't proceed if address or dwelling unit is missing
		if (!address?.result || !address?.dwelling_unit) {
			// If dwelling unit is missing, set it from deliverResults
			if (!address?.dwelling_unit && deliverResults?.dwelling_unit) {
				setAddress({
					...address,
					dwelling_unit: deliverResults?.dwelling_unit,
				});
			} else {
				console.error('Missing address or dwelling unit', address);
				setErrors([
					'Vi har problemer med adressen din. Kan du gå tilbake og sjekke leveranse for din adresse på nytt?',
				]);
				return;
			}
		}

		// Don't proceed if chosen product is missing
		if (!Object.keys(chosenProduct)?.length > 0) {
			console.error('Missing chosen product', chosenProduct);
			setErrors([
				'Vi har problemer med produktet du valgte. Kan du gå tilbake og prøve igjen?',
			]);
			return;
		}

		formErrorsRef.current = [];

		// Get the form
		const form = e.target;

		const isFodselsdatoValid =
			form?.fodselsdato?.value &&
			dayjs(form.fodselsdato.value, 'DD/MM/YYYY', true).isValid() &&
			dayjs(form.fodselsdato.value, 'DD/MM/YYYY').isBefore(
				dayjs().subtract(18, 'years')
			);

		if (!isFodselsdatoValid) {
			formErrorsRef.current.push({
				field: 'fodselsdato',
				error: 'Må være minimum 18 år',
			});
		}

		const isOppstartsdatoValid =
			!!form?.oppstartsdato?.value &&
			dayjs(form?.oppstartsdato?.value, 'DD/MM/YYYY', true).isValid() &&
			dayjs(form?.oppstartsdato?.value, 'DD/MM/YYYY').isAfter(
				dayjs().startOf('day')
			) &&
			dayjs(form?.oppstartsdato?.value, 'DD/MM/YYYY').isBefore(
				dayjs().add(1, 'year').startOf('day')
			);

		if (
			order?.oppstart?.beskrivelse === 'Jeg vil utsette oppstartsdato' &&
			!isOppstartsdatoValid
		) {
			formErrorsRef.current.push({
				field: 'oppstartsdato',
				error: 'Må være en gyldig dato i fremtiden',
			});
		}

		if (formErrorsRef.current.length > 0) {
			console.log('Field errors: ', formErrorsRef.current);
			const firstError = formErrorsRef.current[0];
			const domElement = document?.querySelector(`#${firstError?.field}`);
			if (domElement) scrollToElement(domElement, 300);

			return;
		}

		// BRL: Scroll to top base offer if no product is chosen and don't proceed
		const baseOfferElement = document?.getElementById('base-offer');
		if (isBRL && !chosenProduct?.slug && baseOfferElement) {
			scrollToElement(baseOfferElement);
			return;
		}

		// Define order data
		const orderData = {
			fornavn: form?.fornavn?.value,
			etternavn: form?.etternavn?.value,
			epost: form?.epost?.value,
			telefon: form?.telefon?.value,
			fodselsdato: form?.fodselsdato?.value,

			adresse: address?.result,
			bruksenhetsnummer: address?.dwelling_unit,
			tilhorer_borettslag: isBRL,
			faktureringsadresse:
				form?.faktureringsadresse?.value || address?.result,

			produkt: chosenProduct?.title || '',
			tilleggsprodukter:
				(chosenAddOns?.length > 0 &&
					chosenAddOns?.map(c => ({
						tittel: c?.title,
						antall: c?.quantity || 0,
					}))) ||
				[],

			oppstart: {
				beskrivelse:
					order?.oppstart?.beskrivelse ||
					`Så snart som mulig. ${
						deliverResults?.deliverable?.activation_time || ''
					}.`,
				oppstartsdato:
					(order?.oppstart?.beskrivelse ===
						'Jeg vil utsette oppstartsdato' &&
						form?.oppstartsdato?.value) ||
					undefined,
			},

			godtatte_vilkaar: [],
			leveringsdetaljer: {
				address_id: address?.id ? parseInt(address?.id) : undefined,
				servicepoint_id: deliverResults?.servicepoint_id,
				landing_page: deliverResults?.deliverable?.landing_page,
				bundle_id:
					chosenProduct?.api_id && parseInt(chosenProduct?.api_id),
			},
		};

		// Update order state with the order data
		setOrder(orderData);

		trackBackEnd('Checkout Step Completed', {
			step: 1,
			category: 'TV og Internett',
			payment_method: method,
			landing_page: deliverResults?.deliverable?.landing_page,
		});

		navigate(`/${mainPageSlug}/bestill/steg2`);
	}

	// BRL: Define base products and upgradable-products
	const baseProducts =
		deliverResults?.products?.length > 0 &&
		deliverResults?.products?.filter(p => p?.base_offer === true);

	const upgrades =
		deliverResults?.products?.length > 0 &&
		deliverResults?.products?.filter(p => p?.base_offer !== true);

	const sortedUpgrades =
		(upgrades?.length > 0 && sortByPrice(upgrades, 'priceMonth')) || [];

	// If loading, show loading-component
	if (loading) {
		return <Loading text="Laster ..." style={{ paddingTop: '60px' }} />;
	}

	return (
		<form
			onSubmit={onProceedClick}
			id="order-form-step-1"
			data-cy="fiber-order-form-step-1">
			<TitleAndText
				title="Supert"
				text="Sjekk at opplysningene vi har stemmer og fyll eventuelt inn det som mangler."
				nested={true}
			/>

			<ContactInfo errors={formErrorsRef.current} />

			{/** ADD-ONS Select */}
			{(!isBRL && addOns?.length > 0 && (
				<Fieldset>
					<legend id="product-add-ons-label">
						Er du også interessert i?
					</legend>

					<ProductOptions aria-labelledby="product-add-ons-label">
						{addOns?.map(addon => {
							return (
								<ProductSelect
									key={addon?.slug}
									category="add-on"
									multiple={true}
									onSelect={value => {
										// If the value is 0, remove from chosen add-ons
										if (value === 0) {
											removeFromChosenAddons(addon);
											return;
										}

										trackBackEnd('Product Added', {
											product_id: addon?.slug,
											landing_page:
												deliverResults?.deliverable
													?.landing_page,
											category: 'TV og Internett',
											name: addon?.title,
											price: addon?.priceMonth || 0,
											quantity: value,
											method: 'Bestilling',
										});

										// Define data for the addon
										const data = {
											title: addon?.title,
											slug: addon?.slug,
											priceMonth: addon?.priceMonth || 0,
											establishmentFee:
												addon?.establishmentFee,
											establishmentFeeDiscount:
												addon?.establishmentFeeDiscount,
											quantity: value,
										};

										// Update chosen add-ons
										setChosenAddOns(data);
									}}
									{...addon}
								/>
							);
						})}
					</ProductOptions>
				</Fieldset>
			)) ||
				(isBRL && baseProducts?.length > 0 && (
					/** BRL-PRODUCT Select */
					<Fieldset id="base-offer">
						<legend id="product-options-label">
							{`Velg produkt fra ${baseProducts[0]?.base_offer_name}`}{' '}
							<Req>*</Req>
						</legend>
						<p>
							Alle beboerne vil få pakken{' '}
							{baseProducts[0]?.base_offer_name}. Dette er vår
							mest populære løsning, tilpasset boligselskap med
							behov for god bredbånds-kapasitet, og ønske om en
							fleksibel tv- og underholdningspakke. Som beboer kan
							du selv velge om du kun vil ha bredbånd, eller begge
							deler.
						</p>

						<ProductOptions aria-labelledby="product-options-label">
							{baseProducts?.map(prod => (
								<ProductSelect
									key={prod?.slug}
									{...prod}
									title={prod?.title}
									category="brl"
									removeAllowed={!isBRL}
									onSelect={value => {
										const chosen = baseProducts?.find(
											a => a?.slug === value
										);

										if (!chosen) return;
										setChosenProduct(chosen);
										setOrder({
											...order,
											produkt: chosen?.title,
										});
									}}
								/>
							))}
						</ProductOptions>

						{sortedUpgrades?.length > 0 && (
							<>
								<legend
									id="product-upgrades-label"
									className="small"
									style={{ marginTop: '45px' }}>
									Du kan også oppgradere mot en ekstra kostnad
								</legend>
								<ProductOptions aria-labelledby="product-upgrades-label">
									{sortedUpgrades?.map(prod => (
										<ProductSelect
											key={prod?.slug}
											{...prod}
											category="brl"
											priceType="upgrade"
											removeAllowed={!isBRL}
											title={prod?.title}
											onSelect={value => {
												const chosen = upgrades?.find(
													a => a?.slug === value
												);

												if (!chosen) return;
												setChosenProduct(chosen);
												setOrder({
													...order,
													produkt: chosen?.title,
												});
											}}
										/>
									))}
								</ProductOptions>
							</>
						)}
					</Fieldset>
				))}

			<Button
				type="submit"
				data-cy="fiber-order-form-step1-submit"
				aria-label="Gå videre til siste steg"
				disabled={isBRL && !chosenProduct?.slug}>
				Gå videre til siste steg
			</Button>

			{errors?.length > 0 && (
				<Error
					text={
						errors?.length > 0
							? `${errors?.[0]}`
							: 'Noe galt skjedde'
					}
					style={{ marginTop: '20px' }}
					resolver="orderFiberDelivery"
					errorMessage={
						errors?.[0] || 'Order fiber delivery step 1 failed'
					}
					popup={
						<LeadForm
							title="Kontakt oss"
							formTitle={null}
							context={`Feil med steg 1 i fiberbestilling: ${errors?.[0]}. Resolver: "orderFiberDelivery"`}
							text="Gi oss beskjed om feilen, så tar vi kontakt med deg."
							showLinkToDevelopmentPage={false}
							shadow={false}
							spacing={{ top: 'none', bottom: 'none' }}
							style={{ padding: '0' }}
						/>
					}
				/>
			)}
		</form>
	);
}
